import React from 'react'
import Layout from '../../components/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import episodeArt from '../../images/song_art/episode1.png'

const Episode1 = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">the narrator will spellbind you but please notice the sounds</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container podcast is-fluid">
        <div className="columns name">
          <div className="column">
            <h1 className="is-size-4">Episode 1: 'cross the Glistening Sands</h1>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <img src={episodeArt} className="episode-art" alt="'cross the glistening sands... something is dreaming (on desert background)" />
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/7024571/height/50/theme/legacy/thumbnail/yes/preload/no/direction/backward/" height="50" width="100%" scrolling="no"></iframe>
          </div>
          <div className="column is-two-thirds transcript">
            <p>
              Hello. Welcome to Planet and Sky, a cosmic love story… the podcast. The story I’m about to tell you was originally composed as a modern myth told in music. The recording stands alone as a compelling lyrical rock opera, with some sophisticated and powerful music, which we’ll be introducing over this and the following nine episodes of this podcast, with releases once a week. At the conclusion, the full CD will be available in its entirety on iTunes, etc., for your listening enjoyment.
            </p>

            <p>
              The purpose of this podcast, however, is to develop the deeper story behind the music and the sometimes cryptic lyrics. It’s a story that is at times mysterious and strange, but also resonates with core human experience, particularly that of love and loss, resilience and recovery. It turns out that, even if you follow the lyrics of the songs closely, there are still surprises to be found only in the deeper story and it will now be my pleasure to bring them to you.
            </p>

            <p>
              Wind tore across the surface of the small, arid planet. Dust storms and a frigid atmosphere made it nearly impossible to see there, let alone breathe. It was the sort of place seldom referenced on astronomical databases, let alone visited. And yet parked there in the dense cold and darkness was an expeditionary spacecraft, with two pairs of rapidly vanishing footprints leading away from the airlock.
            </p>

            <p>
              Nearby, the explorers bent into the whipping winds, the air too thin to breathe and bitterly cold if not for the protection of their heated pressure suits. A woman, an astrobiologist, was seeking to find evidence of life, while a man, a planetologist sought to understand everything he could about the structure of the rock beneath them and the mineral composition of this unknown world. If there had ever been a lush and benevolent climate it would have been billions of years in the past and the clues to its presence would be hidden well. The scientists were well trained and adept in the studies of chemical signatures and of the conditions required for life to flourish in such inhospitable conditions.
            </p>

            <p>
              They were approaching a cleft in the surface that provided an opportunity to peer through the planet’s veil... affording access to the secrets hidden below. The scientists weren’t targeting this particular location without reason. An enigmatic signal had been detected from orbit. Not chemical, nor any known form of electromagnetic radiation, but a signal nonetheless... and the searchers now lowered themselves into the subterranean void out of which it had emanated.
            </p>

            <p>
              They descended into the depths of the cavern, observing their surroundings carefully while monitoring and recording every detail using instruments built into their suits. After navigating a narrow and difficult passage they came upon a roughly circular room of considerable size. Here, they entered a dark and quiet chamber, completely isolated from the relentless whirling of surface winds. The explorers read their instruments with astonishment, as the air was now indicated to be breathable with the ambient temperature having risen to a comfortable level.
            </p>

            <p>
              Surprised, the woman found herself unlatching her helmet. The man hesitantly did the same, and both cautiously inhaled the moist and fragrant atmosphere. After only a moment had passed, the scientists experienced a sense of peace and wellbeing overcoming them. Though they were deep in the bowels of a strange planet, they had a sense that they could completely trust the environment. They then doused the illumination from their suits and settled into a welcoming darkness. It became apparent as their eyes adjusted that the chamber contained its own light source. An iridescent glow gently illuminated their awed expressions.
            </p>

            <p>
              “Amazing…” said the astrobiologist, “but I don’t get it. We should be gasping for air right now.”
            </p>

            <p>
              “I’m as baffled as you are...” the planetologist replied. “and yet… I don’t know why, but somehow I feel sure that we’re in no danger.”
            </p>

            <p>
              “Yes,” said the woman, “and I’m also feeling some powerful emotions… an overtone of deep sadness mixed with something joyful… even triumphant. Is it just me?”
            </p>

            <p>
              “No, it seems to be effecting me too,” said the planetologist. “I’m getting a strong feeling of melancholy... but mixed with a sense of purpose… and… accomplishment. Like how you feel when you’ve done the best you could possibly do with a bad situation. The weird thing is these don’t feel like my own thoughts, like something is thinking for me.”
            </p>

            <p>
              There was a lot to do, a full slate of mission objectives, so the scientists activated an array of sensors to begin recording detailed analyses of multiple parameters to investigate the mysterious signal. They had to gather as much data as possible and determine what could be producing this unexpectedly hospitable atmosphere beneath the planet’s surface, and the strange sensations they were experiencing there. They had a lot of information to transmit to the scientists at the base station.
            </p>

            <p>
              Suddenly the woman stopped and looked at the floor of the chamber and her face lit up in recognition. “This looks like a microbial mat! There’s a macro structure… but it’s not dendritic, more like a honeycomb - multicellular on a hexagonal template.”
            </p>

            <p>
              The man noted “The rock here is primarily micaceous clay, a mineral known to…”
            </p>

            <p>
              “...potentially hold electric charge.” she finished for him. “These have been proposed but never found, maybe until now. I wonder if the clay could be imprinted in some way… some kind of biological, magnetic interaction… maybe producing a field capable of resonating with our nervous systems? Could it be a form of communication?”
            </p>

            <p>
              “Or an attack?” he answered ominously.
            </p>

            <p>
              A lengthy set of requirements had been prepared in the unlikely event of an encounter with a life form or intelligent entity of some kind. Breathing the strange air, and with the mass of the alien planet hulking over them, the observers strained to maintain a scientific and rational mindset and faithfully followed their protocols. Among the required procedures was a directive to take extreme effort to avoid any harm to the subject of their encounter. If an intelligence were at work here, it was critical not to project hostility or disrespect prior to gaining a more complete understanding of what they were dealing with.
            </p>

            <p>
              Despite the high regard which she held for these protocols, a serene confidence had come over the woman that whatever energy was resident on the planet was well-meaning, and held no ill will toward them. All rational impulse was swept away by a wave of euphoria compelling her to believe she had nothing to fear. The force had not yet overcome her partner, however, who wondered momentarily if they'd stumbled into some kind of biochemical lure akin to the way the sirens of legend were once reputed to have lured sailors to their deaths. Regaining some sense of detachment they began to assess the situation.
            </p>

            <p>
              “Let’s not assume it to be benevolent,” the man cautioned. “Let’s get as much data back to base as we can and hope the team can make sense of this.”
            </p>

            <p>
              The woman tried hard to stay objective. “Absolutely. Caution is advised until we have a better understanding… but wow. Do you feel that?”
            </p>

            <p>
              She was reacting to a new and very peculiar sensation. A feeling of a lightness of being as if the force of gravity was lessening. The mental disturbances were increasing as well. Her perspective shifted dramatically - she could sense the outside of the chamber, beyond the surface, and felt she could extend her senses into the highest and thinnest reaches of the planet’s atmosphere. Her emotions were also fluctuating strangely. She began to feel the presence of her companion with a powerful intimacy. No longer just a crew member and co-worker, she felt bonded to him as if he was essential to her very existence.
            </p>

            <p>
              The man had gone silent, but he too was experiencing new feelings beyond his understanding, beginning with a loss of connection to his body and at the same time a sense of deep isolation. He felt an emergence from nothingness… rooted at the center of a vast geometrical space. A heaviness pulling him down in every direction. He’d lost awareness of his companion and felt entirely alone.
            </p>

            <p>
              Back at the base, the chief scientist found himself perplexed and alarmed by the sudden loss of communications. Instruments were still picking up data, but it was highly ambiguous. Patterns were emerging in the data that seemed to represent geometrical order, and possibly some kind of communication, but it wasn’t coming from the two explorers.
            </p>

            <p>
              A hasty convocation of specialists isolated a spherical pattern in the data, an unfamiliar, but highly organized signature. The conclusion became inescapable. This could not be just random noise. Though it hardly seemed possible, it seemed this could only be the product of some kind of intelligence! The team set to work analyzing the data stream in search of a key to decode what was becoming a veritable flood of information. Cryptographic algorithms were engaged and in time an outline began to emerge suggestive of a narrative being presented from deep in time. The planet was revealing an ancient, primeval story.
            </p>

            <p>
              In the beginning was the orb, cooling from its primordial stew of components and outgassing as it solidified and settling into a solid mass, barren beneath the stars. A whisper of a thought was heard, faintly musical, not from the surface but from the cold and empty skies. The winds whirled and shifted and, in the manner in which the fine particles of atmosphere flowed across the surface, standing waves arose... semi-permanent patterns that held fast and took bodily shape high above the surface. A form and face vaguely human and distinctly feminine.
            </p>

            <p>
              Above the howling winds a voice could faintly be heard,
            </p>

            <p>
              “Silently appearing high above this world<br/>
               Reaching out inquisitively my senses are unfurled<br/>
               Dancing joyous floating free<br/>
               Impulse comes to bind myself to thee”
            </p>

            <p>
              Upon the roiling surface of the new world similar forms emerged from topography of the newborn planet. They took on a masculine form and exuded stolid power.
            </p>

            <p>
              A lower tone was now heard, forming into a deeper song,
            </p>

            <p>
              “Alone I emerge, as the stars provide<br/>
               Outward facing emptiness - in depths my soul resides”
            </p>

            <p>
              The data stream was overwhelming the capability of the science team to fully contain it. One by one the various sensors exceeded capacity and went offline but still the phenomena did not abate. A roar filled the command center and the scientists stopped working entirely. All were spectators now.
            </p>

            <p>
              Deep underground, the man had moved to the center of the cave, his exploration suit discarded. He sat, in solemn contemplation. He was spherical and dense, and utterly alone in the universe. The woman, also now free of her encumbering suit, was in graceful motion around the outside of the cave, swirling and dancing. She felt light and insubstantial, but her motions were fixed to the center of the chamber where the man pondered his inner depths, in complete isolation.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Episode1
